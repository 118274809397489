import { NextPage } from 'next'

import NotFoundTemplate from '../templates/NotFoundTemplate'
import { initWithLangAndLabels } from '../i18n'
import { PageProps } from '../relay/Common/PageProps'
import getConfiguration from '../rest/services/getConfiguration'
import useProps from '../relay/NotFound/useProps'

export type NotFoundPageProps = PageProps

const NotFoundPage: NextPage<NotFoundPageProps> = ({ configuration }) => {
  const notFoundProps = useProps({ configuration })
  return <NotFoundTemplate {...notFoundProps} />
}

export async function getStaticProps() {
  const i18n = initWithLangAndLabels('fr', {})
  const [configuration] = await Promise.all([getConfiguration()])

  return {
    props: {
      i18n,
      configuration,
    },
    revalidate: 60 * 60, // every hour max
  }
}

export default NotFoundPage
