import { useTranslation } from 'react-i18next'

import { Configuration } from '../../rest/types/Configuration'
import { NotFoundTemplateProps } from '../../templates'
import useFooter from '../Footer/useFooter'
import { Icons } from '../../components/Icon'
import { ActionButtonVariant } from '../../components/ActionButton/styled'
import routes from '../../routes/definitions'
import Router from '../../routes/Router'
import useHeaderBis from '../header/useHeaderBis'

const useProps = ({ configuration }: { configuration: Configuration }) => {
  const { t } = useTranslation()

  const headerProps = useHeaderBis(configuration, false)
  const footerProps = useFooter(configuration)

  const notFoundProps: NotFoundTemplateProps = {
    headerProps,
    footerProps,
    title: t('404_title'),
    description: t('404_text'),
    buttonProps: {
      label: t('404_button'),
      iconPosition: 'right',
      iconProps: {
        icon: Icons.arrowRight,
      },
      variant: ActionButtonVariant.secondary,
      href: Router.getRouteUrl(routes.home),
    },
  }

  return notFoundProps
}

export default useProps
