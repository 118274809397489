import React, { FC } from 'react'
import NextImage from 'next/image'

import { ActionButtonProps } from '../../components/ActionButton'
import { MainLayoutProps } from '../../layouts/MainLayout'

import * as SC from './styled'

export type NotFoundTemplateProps = MainLayoutProps & {
  title?: string
  description?: string
  buttonProps?: ActionButtonProps
}

const NotFoundTemplate: FC<NotFoundTemplateProps> = ({
  title,
  description,
  buttonProps,
  ...layoutProps
}) => {
  return (
    <SC.Layout {...layoutProps}>
      <SC.Container>
        <SC.Content>
          <NextImage
            width={330}
            height={165}
            src={'/static/assets/images/404_unpluged.png'}
            alt={'image'}
          />
          {title && <SC.Title dangerouslySetInnerHTML={{ __html: title }} />}
          {description && (
            <SC.Description dangerouslySetInnerHTML={{ __html: description }} />
          )}
          {buttonProps && <SC.Button {...buttonProps} />}
          <SC.CrossesTop width={120} />
          <SC.CrossesBottom width={120} />
        </SC.Content>
      </SC.Container>
    </SC.Layout>
  )
}

export default NotFoundTemplate
